import { render, staticRenderFns } from "./Commissions.vue?vue&type=template&id=bc99571c&scoped=true&"
import script from "./Commissions.vue?vue&type=script&lang=js&"
export * from "./Commissions.vue?vue&type=script&lang=js&"
import style0 from "./Commissions.vue?vue&type=style&index=0&id=bc99571c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc99571c",
  null
  
)

export default component.exports